/* eslint-disable max-len */
import { ReactElement } from 'react';
import { useConfirmationModalContext } from '../../hooks/useConfirmationModalContext';
import { isValidCode } from '../../services/base.service';
import { matchStudent } from '../../services/student.service';
import { ApiResponseModel } from '../../types/ApiResponseModel';
import { ButtonSize } from '../../types/ButtonSize';
import { MergeStudentPanelType } from '../../types/MergeStudentPanelType';
import {
  MSISMatchStrength,
  MSISRequestMatchModel,
} from '../../types/MSISRequestMatchModel';
import { StudentMatchesPropTypes } from '../../types/propTypes/StudentMatchesPropTypes';
import ActionButton from '../ActionButton/ActionButton';
import ConfirmMatch from './ConfirmMatch/ConfirmMatch';
import MatchDisplay from './MatchDisplay/MatchDisplay';
import MergeStudentPanel from './MergeStudentPanel/MergeStudentPanel';
import NoStudentMatchSelection from './NoStudentMatchSelection/NoStudentMatchSelection';
import { isStateHelpdesk } from '../../utilities/userUtilities';
import './StudentMatches.css';

const StudentMatches = ({
  model,
  onSuccess,
}: StudentMatchesPropTypes): ReactElement => {
  const confirmContext = useConfirmationModalContext();

  const getMatchTest = (strength: MSISMatchStrength): string => {
    switch (strength) {
      case MSISMatchStrength.Weak:
        return 'Weak Match';
      case MSISMatchStrength.Medium:
        return 'Possible Match';
      case MSISMatchStrength.Strong:
        return 'Strong Match';
    }
  };

  const handleConfirm = async (
    parentId: string,
    matchMSIS: string | undefined
  ): Promise<ApiResponseModel<unknown>> => {
    return await matchStudent({
      queryId: parentId,
      selectedMsisId: matchMSIS,
    });
  };

  const handleNoMatch = async (): Promise<void> => {
    confirmContext.setOptions({
      title: 'Are you sure you want to create a new MSIS ID?',
      content: (
        <div className="new-match-container">
          <MergeStudentPanel
            panelType={MergeStudentPanelType.NEW}
            student={model as MSISRequestMatchModel}
            cypressDataId={'new-student-panel'}
          />
        </div>
      ),
      confirmText: 'Yes, create new ID',
      cancelText: 'No, cancel',
      onOk: async (): Promise<ApiResponseModel<unknown>> => {
        const response = await handleConfirm(model.id, undefined);
        if (isValidCode(response.status)) {
          onSuccess && (await onSuccess('MSIS ID was assigned.'));
        }
        return response;
      },
    });

    await confirmContext.showConfirmation();
  };

  const handleMatch = async (match: MSISRequestMatchModel): Promise<void> => {
    confirmContext.setOptions({
      title: 'Are you sure this is a match?',
      content: (
        <ConfirmMatch
          model={model as MSISRequestMatchModel}
          match={match}
        ></ConfirmMatch>
      ),
      confirmText: 'Yes, students match',
      cancelText: 'No, cancel',
      onOk: async (): Promise<ApiResponseModel<unknown>> => {
        const response = await handleConfirm(model.id, match.msisId);
        if (isValidCode(response.status)) {
          onSuccess &&
            (await onSuccess('Student was updated. No new ID was assigned.'));
        }
        return response;
      },
    });

    await confirmContext.showConfirmation();
  };

  return !model.msisId ? (
    <div
      className="msis-request-detail-matches"
      data-cy="msis-request-detail-matches-container"
    >
      <h3>Please review students for potential matches</h3>
      <div className="msis-request-detail-matches-subheading">
        If you see more than one match, please contact your MSIS Primary for
        assistance.
      </div>
      {model.matches?.map((match) => (
        <div
          key={match.id}
          data-testid="match"
          className="msis-request-detail-match--container"
        >
          <div
            data-testid="match-heading"
            className={`msis-request-detail-match--heading ${match.matchStrength.toLowerCase()}-heading`}
          >
            <div className="msis-request-detail-match-name">
              {match.fullName}
            </div>
            <div className="msis-request-detail-match-id">#{match.msisId}</div>
            <div
              data-testid="match-badge"
              className={`msis-request-detail-match-strength-badge ${match.matchStrength.toLowerCase()}-badge`}
            >
              {getMatchTest(match.matchStrength)}
            </div>
          </div>
          <div className="msis-request-detail-matched-data--container">
            <MatchDisplay match={match} />
            {match.aliases.length > 0 && (
              <div className="alias-display" data-testid="alias-list">
                <table cellSpacing={0} className="page-view-table">
                  <thead>
                    <tr>
                      <th>Aliases</th>
                    </tr>
                  </thead>
                  <tbody>
                    {match.aliases.map((name, index) => (
                      <tr key={`alias-${index}`} data-testid={`alias-${index}`}>
                        <td>{name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="match-action-container">
            {!isStateHelpdesk() && (
            <ActionButton
              cypressDataId={`confirm-match-button`}
              onClick={() => handleMatch(match)}
              dataTestId={`button-match-${model.id}-${match.msisId}`}
              size={ButtonSize.SMALL}
              tooltipText="Confirm Match"
            >
              <>Confirm Match</>
            </ActionButton>
            )}
          </div>
        </div>
      ))}
      <NoStudentMatchSelection handleAction={handleNoMatch} />
    </div>
  ) : (
    <></>
  );
};

export default StudentMatches;
