/* eslint-disable max-len */
import { VALIDATION_MESSAGE } from '../ValidationMessage';
import yup from './CustomValidation/yup-extended';

export const SchoolManagementValidationSchema = yup.object().shape(
  {
    schoolName: yup
      .string()
      .required(VALIDATION_MESSAGE.REQUIRED)
      .min(1, VALIDATION_MESSAGE.EXACT_LENGTH(1))
      .max(60, VALIDATION_MESSAGE.AT_MOST_CHARS(60)),
    schoolType: yup
      .string()
      .required(VALIDATION_MESSAGE.REQUIRED) // `schoolType` is required.
      .when(
        'schoolIdentifierSchoolPart',
        (schoolIdentifierSchoolPart, schema) => {
          switch (schoolIdentifierSchoolPart) {
            case '500':
              return schema.oneOf(
                ['Special Education School'],
                'If school ID is 500, School Type must be Special Education School'
              );
            case '200':
              return schema.oneOf(
                ['Career And Technical Education School'],
                'If school ID is 200, School Type must be Career and Technical Education School'
              );
            default:
              return schema.notOneOf(
                [
                  'Special Education School',
                  'Career And Technical Education School',
                ],
                'Invalid school type for the given school ID'
              );
          }
        }
      ),
    reportedFederally: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
    actIdentifier: yup
      .string()
      .mustBeNumber()
      .max(50, VALIDATION_MESSAGE.AT_MOST_CHARS(50)),
    ncesIdentifier: yup
      .string()
      .mustBeNumber()
      .min(7, VALIDATION_MESSAGE.MUST_BE_EXACT_NUMBER_LENGTH(7))
      .max(7, VALIDATION_MESSAGE.AT_MOST_CHARS(7)),
    charterSchool: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
    charterSchoolType: yup
      .string()
      .required(VALIDATION_MESSAGE.REQUIRED)
      .when('charterSchool', {
        is: (charterSchool: string) => charterSchool === 'Yes',
        then: yup
          .string()
          .notOneOf(
            ['Not a Charter School'],
            'If charter school is YES, then Charter School Type cannot be Not a Chater School'
          ),
      }),
    schoolIdentifierSchoolPart: yup
      .string()
      .required(VALIDATION_MESSAGE.REQUIRED)
      .mustBeNumber()
      .min(3, VALIDATION_MESSAGE.EXACT_NUMBER_LENGTH(3))
      .max(3, VALIDATION_MESSAGE.AT_MOST_CHARS(3)),
    schoolOperationalStatus: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
    operationalStatusEffectiveDate: yup
      .date()
      .required(VALIDATION_MESSAGE.REQUIRED),
    grades: yup
      .array(),
    website: yup.string().max(300, VALIDATION_MESSAGE.AT_MOST_CHARS(300)),
    phone: yup
      .string()
      .matches(
        /^[1-9]\d{2}-\d{3}-\d{4}$/,
        VALIDATION_MESSAGE.MUST_BE_IN_PHONE_NUMBER_FORMAT
      ),
    physicalAddressStreetNumberAndName: yup
      .string()
      .max(150, VALIDATION_MESSAGE.AT_MOST_CHARS(150))
      .ensure()
      .when(['physicalCity', 'physicalState', 'physicalZipCode'], {
        is: (
          physicalCity: string,
          physicalState: string,
          physicalZipCode: string
        ) =>
          physicalCity !== '' || physicalState !== '' || physicalZipCode !== '',
        then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
        otherwise: yup.string().notRequired(),
      }),
    physicalAptSuiteBuildingFloorNumber: yup
      .string()
      .max(60, VALIDATION_MESSAGE.AT_MOST_CHARS(60)),
    physicalCity: yup
      .string()
      .max(30, VALIDATION_MESSAGE.AT_MOST_CHARS(30))
      .ensure()
      .when(
        [
          'physicalAddressStreetNumberAndName',
          'physicalState',
          'physicalZipCode',
        ],
        {
          is: (
            physicalAddressStreetNumberAndName: string,
            physicalState: string,
            physicalZipCode: string
          ) =>
            physicalAddressStreetNumberAndName !== '' ||
            physicalState !== '' ||
            physicalZipCode !== '',
          then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
          otherwise: yup.string().notRequired(),
        }
      ),
    physicalState: yup
      .string()
      .ensure()
      .when(
        [
          'physicalAddressStreetNumberAndName',
          'physicalCity',
          'physicalZipCode',
        ],
        {
          is: (
            physicalAddressStreetNumberAndName: string,
            physicalCity: string,
            physicalZipCode: string
          ) =>
            physicalAddressStreetNumberAndName !== '' ||
            physicalCity !== '' ||
            physicalZipCode !== '',
          then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
          otherwise: yup.string().notRequired(),
        }
      ),
    physicalZipCode: yup
      .string()
      .max(17, VALIDATION_MESSAGE.AT_MOST_CHARS(17))
      .ensure()
      .when(
        ['physicalAddressStreetNumberAndName', 'physicalCity', 'physicalState'],
        {
          is: (
            physicalAddressStreetNumberAndName: string,
            physicalCity: string,
            physicalState: string
          ) =>
            physicalAddressStreetNumberAndName !== '' ||
            physicalCity !== '' ||
            physicalState !== '',
          then: yup
            .string()
            .required(VALIDATION_MESSAGE.REQUIRED)
            .matches(
              /^[0-9\-]{5,17}$/,
              VALIDATION_MESSAGE.MUST_BE_IN_ZIP_CODE_FORMAT
            ),
          otherwise: yup.string().notRequired(),
        }
      ),
    physicalCounty: yup
      .string()
      .ensure()
      .when(['physicalState'], {
        is: (physicalState: string) => physicalState === 'Mississippi',
        then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
        otherwise: yup.string().notRequired(),
      }),
    mailingAddressStreetNumberAndName: yup
      .string()
      .max(150, VALIDATION_MESSAGE.AT_MOST_CHARS(150))
      .ensure()
      .when(['mailingCity', 'mailingState', 'mailingZipCode'], {
        is: (
          mailingCity: string,
          mailingState: string,
          mailingZipCode: string
        ) => mailingCity !== '' || mailingState !== '' || mailingZipCode !== '',
        then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
        otherwise: yup.string().notRequired(),
      }),
    mailingAptSuiteBuildingFloorNumber: yup
      .string()
      .max(60, VALIDATION_MESSAGE.AT_MOST_CHARS(60)),
    mailingCity: yup
      .string()
      .max(30, VALIDATION_MESSAGE.AT_MOST_CHARS(30))
      .ensure()
      .when(
        ['mailingAddressStreetNumberAndName', 'mailingState', 'mailingZipCode'],
        {
          is: (
            mailingAddressStreetNumberAndName: string,
            mailingState: string,
            mailingZipCode: string
          ) =>
            mailingAddressStreetNumberAndName !== '' ||
            mailingState !== '' ||
            mailingZipCode !== '',
          then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
          otherwise: yup.string().notRequired(),
        }
      ),
    mailingState: yup
      .string()
      .ensure()
      .when(
        ['mailingAddressStreetNumberAndName', 'mailingCity', 'mailingZipCode'],
        {
          is: (
            mailingAddressStreetNumberAndName: string,
            mailingCity: string,
            mailingZipCode: string
          ) =>
            mailingAddressStreetNumberAndName !== '' ||
            mailingCity !== '' ||
            mailingZipCode !== '',
          then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
          otherwise: yup.string().notRequired(),
        }
      ),
    mailingZipCode: yup
      .string()
      .max(17, VALIDATION_MESSAGE.AT_MOST_CHARS(17))
      .ensure()
      .when(
        ['mailingAddressStreetNumberAndName', 'mailingCity', 'mailingState'],
        {
          is: (
            mailingAddressStreetNumberAndName: string,
            mailingCity: string,
            mailingState: string
          ) =>
            mailingAddressStreetNumberAndName !== '' ||
            mailingCity !== '' ||
            mailingState !== '',
          then: yup
            .string()
            .required(VALIDATION_MESSAGE.REQUIRED)
            .matches(
              /^[0-9\-]{5,17}$/,
              VALIDATION_MESSAGE.MUST_BE_IN_ZIP_CODE_FORMAT
            ),
          otherwise: yup.string().notRequired(),
        }
      ),
    mailingCounty: yup
      .string()
      .ensure()
      .when(['mailingState'], {
        is: (mailingState: string) => mailingState === 'Mississippi',
        then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
        otherwise: yup.string().notRequired(),
      }),
  },
  [
    ['mailingAddressStreetNumberAndName', 'mailingCity'],
    ['mailingAddressStreetNumberAndName', 'mailingState'],
    ['mailingAddressStreetNumberAndName', 'mailingZipCode'],
    ['mailingCity', 'mailingState'],
    ['mailingState', 'mailingZipCode'],
    ['mailingCity', 'mailingZipCode'],
    ['physicalAddressStreetNumberAndName', 'physicalCity'],
    ['physicalAddressStreetNumberAndName', 'physicalState'],
    ['physicalAddressStreetNumberAndName', 'physicalZipCode'],
    ['physicalCity', 'physicalState'],
    ['physicalState', 'physicalZipCode'],
    ['physicalCity', 'physicalZipCode'],
  ]
);
