/* eslint-disable max-len */
import { ReactElement } from 'react';
import { FileErrorDetailsPropTypes } from '../../types/propTypes/FileErrorDetailsPropTypes';
import './FileErrorDetails.css';

const FileErrorDetails = ({
  title,
}: FileErrorDetailsPropTypes): ReactElement => (
  <div className="file-error-details" data-status="Failed">
    {`${title} There are errors in your data that need your attention.`}
  </div>
);

export default FileErrorDetails;
