/* eslint-disable max-len */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataEntryMode } from '../../types/DataEntryMode';
import { SchoolManagementFormViewModel } from '../../types/SchoolManagementFormViewModel';
import { SchoolManagementListItemModel } from '../../types/SchoolManagementListItemModel';
import { SchoolManagementModel } from '../../types/SchoolManagementModel';
import { isEmptyObject } from '../../utilities/helperUtilities';
import type { RootState } from '../store';
import {
  fetchSchoolManagementById,
  fetchSchoolManagementFormViewModel,
  fetchSchoolManagementListItems,
} from '../thunks/schoolManagementThunks';

interface SchoolManagementState {
  leaName: string;
  loadingListItems: boolean;
  listItems: SchoolManagementListItemModel[];

  loadingSchoolManagement: boolean;
  schoolManagement: SchoolManagementModel;

  loadingFormViewModel: boolean;
  formViewModel: SchoolManagementFormViewModel;

  dataEntryMode: DataEntryMode;
}

export const defaultListState: SchoolManagementListItemModel[] = [];

export const defaultSchoolManagementState: SchoolManagementModel = {
  id: '',
  leaName: '',
  leaIdentifier: '',
  organizationType: '',
  schoolIdentifier: '',
  schoolName: '',
  schoolType: '',
  actIdentifier: '',
  ncesIdentifier: '',
  charterSchool: '',
  charterSchoolType: '',
  schoolOperationalStatus: 'Open',
  operationalStatusEffectiveDate: '',
  website: '',
  phone: '',
  reportedFederally: '',
  grades: [],
  magnetSchool: '',
  virtualSchool: '',
  mailingAddressStreetNumberAndName: '',
  mailingAptSuiteBuildingFloorNumber: '',
  mailingCity: '',
  mailingState: '',
  mailingZipCode: '',
  mailingCounty: '',
  mailingCountyAnsiCode: '',
  physicalAddressStreetNumberAndName: '',
  physicalAptSuiteBuildingFloorNumber: '',
  physicalCity: '',
  physicalState: '',
  physicalZipCode: '',
  physicalCounty: '',
  physicalCountyAnsiCode: '',
  schoolIdentifierLeaPart: '',
  schoolIdentifierSchoolPart: '',
};

export const defaultFormState: SchoolManagementFormViewModel = {
  charterSchoolTypeOptions: [],
  gradeOptions: [],
  schoolTypeOptions: [],
  schoolOperationalStatusOptions: [],
  stateOptions: [],
  countyOptions: [],
  countyAnsiCodeOptions: [],
};

export const dataEntryMode = DataEntryMode.NEW;

export const initialState: SchoolManagementState = {
  leaName: '',
  loadingListItems: false,
  listItems: defaultListState,
  loadingSchoolManagement: false,
  schoolManagement: defaultSchoolManagementState,
  loadingFormViewModel: false,
  formViewModel: defaultFormState,
  dataEntryMode: dataEntryMode,
};

export const schoolManagementSlice = createSlice({
  name: 'schoolManagement',
  initialState,
  reducers: {
    setSchoolDistrictFilter: (state, action: PayloadAction<string>) => {
      state.leaName = action.payload;
    },
    clearSchoolManagement: (state) => {
      state.schoolManagement = defaultSchoolManagementState;
    },
    saveSchoolManagement: (
      state,
      action: PayloadAction<SchoolManagementModel>
    ) => {
      state.schoolManagement = action.payload;
      state.schoolManagement.schoolIdentifier = '1234-123';
    },
    setSchoolManagementDataEntryMode: (
      state,
      action: PayloadAction<DataEntryMode>
    ) => {
      state.dataEntryMode = action.payload;
    },
    setSchoolManagementLea: (state, action: PayloadAction<string>) => {
      state.schoolManagement.schoolIdentifierLeaPart = action.payload;
    },
  },
  extraReducers: (builder) => {
    // fetchLeaManagementList
    builder.addCase(fetchSchoolManagementListItems.pending, (state) => {
      state.listItems = defaultListState;
      state.loadingListItems = true;
    });
    builder.addCase(
      fetchSchoolManagementListItems.fulfilled,
      (state, action) => {
        if (!isEmptyObject(action.payload)) {
          state.listItems = action.payload;
        }
        state.loadingListItems = false;
      }
    );
    builder.addCase(
      fetchSchoolManagementListItems.rejected,
      (state, action) => {
        if (!action.meta.aborted) {
          state.loadingListItems = false;
        }
      }
    );

    // fetchLeaManagementById
    builder.addCase(fetchSchoolManagementById.pending, (state) => {
      state.schoolManagement = defaultSchoolManagementState;
      state.loadingSchoolManagement = true;
    });
    builder.addCase(fetchSchoolManagementById.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.schoolManagement = action.payload;
      }
      state.loadingSchoolManagement = false;
    });
    builder.addCase(fetchSchoolManagementById.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loadingSchoolManagement = false;
      }
    });

    // fetchLeaManagementFormViewModel
    builder.addCase(fetchSchoolManagementFormViewModel.pending, (state) => {
      state.formViewModel = defaultFormState;
      state.loadingFormViewModel = true;
    });
    builder.addCase(
      fetchSchoolManagementFormViewModel.fulfilled,
      (state, action) => {
        if (!isEmptyObject(action.payload)) {
          state.formViewModel = action.payload;
        }
        state.loadingFormViewModel = false;
      }
    );
    builder.addCase(
      fetchSchoolManagementFormViewModel.rejected,
      (state, action) => {
        if (!action.meta.aborted) {
          state.loadingFormViewModel = false;
        }
      }
    );
  },
});

export const { setSchoolDistrictFilter } = schoolManagementSlice.actions;

//export reducers
export const {
  clearSchoolManagement,
  saveSchoolManagement,
  setSchoolManagementDataEntryMode,
  setSchoolManagementLea,
} = schoolManagementSlice.actions;

// listItems
export const selectSchoolManagementLoadingListItems = (
  state: RootState
): boolean => state.schoolManagement.loadingListItems;
export const selectSchoolManagementListItems = (
  state: RootState
): SchoolManagementListItemModel[] => state.schoolManagement.listItems;

// leaManagement
export const selectSchoolManagementLoadingSchoolManagement = (
  state: RootState
): boolean => state.schoolManagement.loadingSchoolManagement;
export const selectSchoolManagementSchoolManagement = (
  state: RootState
): SchoolManagementModel => state.schoolManagement.schoolManagement;

// formViewModel
export const selectSchoolManagementLoadingFormViewModel = (
  state: RootState
): boolean => state.schoolManagement.loadingFormViewModel;
export const selectSchoolManagementFormViewModel = (
  state: RootState
): SchoolManagementFormViewModel => state.schoolManagement.formViewModel;

// dataEntryMode
export const selectSchoolManagementDataEntryMode = (
  state: RootState
): DataEntryMode => state.schoolManagement.dataEntryMode;

export default schoolManagementSlice.reducer;
