import { createAsyncThunk } from '@reduxjs/toolkit';
import { thunkExecuteAndReturnResultOrShowError } from '../../services/base.service';
import {
  getSummerActivityById,
  getSummerActivityFormViewModel,
  getSummerActivityListItems,
} from '../../services/SummerActivity.service';
import { SummerActivityFormViewModel } from '../../types/SummerActivityFormViewModel';
import { SummerActivityListItemModel } from '../../types/SummerActivityListItemModel';
import { SummerActivityModel } from '../../types/SummerActivityModel';
import { SummerActivityRequestModel } from '../../types/SummerActivityRequestModel';
import { SummerActivityScholYearListModel } from 'src/types/SummerActivitySchoolYearListModel';

export const fetchSummerActivityListItems = createAsyncThunk(
  'input/fetchSummerActivityListItems',
  async (args: SummerActivityRequestModel, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<
      SummerActivityListItemModel[]
    >(
      thunkAPI,
      getSummerActivityListItems(args.schoolYear, args.school, {
        signal: thunkAPI.signal,
      })
    );
  }
);

export const fetchSummerActivityById = createAsyncThunk(
  'input/fetchSummerActivityById',
  async (summerActivityId: string, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<SummerActivityModel>(
      thunkAPI,
      getSummerActivityById(summerActivityId, { signal: thunkAPI.signal })
    );
  }
);

export const fetchSummerActivityFormViewModel = createAsyncThunk(
  'input/fetchSummerActivityFormViewModel',
  async (params: SummerActivityScholYearListModel, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<SummerActivityFormViewModel>(
      thunkAPI,
      getSummerActivityFormViewModel(
        params.fromSchoolYear,
        params.toSchoolYear,
        { signal: thunkAPI.signal }
      )
    );
  }
);
