/* eslint-disable max-len */
import { useFormikContext } from 'formik';
import { ChangeEvent, ReactElement } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { selectNonPublicStudentFormViewModel } from '../../../redux/slices/nonPublicStudentSlice';
import { getCurrentUserAccess } from '../../../services/base.service';
import {
  DistrictModelExtended,
  SchoolModel,
} from '../../../types/DistrictModel';
import { NonPublicStudentEntryModel } from '../../../types/NonPublicStudentEntryModel';
import {
  NonPublicStudentFormViewModel,
  NPSESchoolType,
} from '../../../types/NonPublicStudentFormViewModel';
import { PanelType } from '../../../types/PanelType';
import { FormRadioListOrientation } from '../../../types/propTypes/FormRadioListPropTypes';
import { NonPublicStudentEntryValidationSchema } from '../../../types/Validation/NonPublicStudentEntryValidationSchema';
import { isStateNonAdmin } from '../../../utilities/userUtilities';
import FormRadioList from '../../FormInputs/FormRadioList/FormRadioList';
import FormTextbox from '../../FormInputs/FormTextbox/FormTextbox';
import Panel from '../../Panel/Panel';
import './NPSE_LEAPanel.css';

const NPSE_LEAPanel = (): ReactElement => {
  const { setFieldValue } = useFormikContext<NonPublicStudentEntryModel>();

  const formViewModel: NonPublicStudentFormViewModel = useAppSelector(
    selectNonPublicStudentFormViewModel
  );

  const getDistrict = (
    currentUserDistrict?: string,
    districtList?: DistrictModelExtended[]
  ): DistrictModelExtended | undefined => {
    const foundDistrict =
      districtList &&
      districtList.filter((x) => x.districtId === currentUserDistrict);
    if (foundDistrict && foundDistrict.length === 1) {
      return foundDistrict[0];
    }
    return undefined;
  };

  const getSchool = (
    district: DistrictModelExtended,
    schoolId: string
  ): SchoolModel | undefined => {
    const foundSchool = district.schools.filter((x) => schoolId === x.schoolId);
    if (foundSchool && foundSchool.length === 1) {
      return foundSchool[0];
    }
    return undefined;
  };

  const prefillSchoolNameAndNumber = (schoolType: string): string => {
    /* istanbul ignore next */
    const districtId =
      getCurrentUserAccess()?.currentDistrict?.districtId || '';

    const schoolNameVal =
      schoolType === NPSESchoolType.CTE
        ? 'CTE Non-Public'
        : 'Special Ed Non-Public';
    const schoolNumberVal =
      schoolType === NPSESchoolType.CTE
        ? `${districtId}-200`
        : `${districtId}-500`;

    setFieldValue('schoolId', schoolNumberVal);
    setFieldValue('schoolName', schoolNameVal);
    setFieldValue('school', `${schoolNameVal} (${schoolNumberVal})`);

    return schoolNumberVal;
  };

  const clearNonUsedFieldForOtherSchoolType = (schoolType: string): void => {
    if (schoolType === NPSESchoolType.SPED) {
      setFieldValue('singleParentOrSinglePregnantWomanStatus', '');
      setFieldValue('cteaeDisplacedHomemakerIndicator', '');
      setFieldValue('perkinsPostProgramPlacementIndicator', '');
    } else {
      setFieldValue('eligibilityDeterminationDate', '');
      setFieldValue('iepStartDate', '');
      setFieldValue('projectedHSDiplomaType', '');
      setFieldValue('programParticipationExitDate', '');
      setFieldValue('primaryDisabilityType', '');
      setFieldValue('secondaryDisabilityType', '');
      setFieldValue('significantCognitiveDisabilityIndicator', '');
      setFieldValue('ideaEducationalEnvironment', '');
      setFieldValue('relatedServices', []);
      setFieldValue('responsibleDistrictAccountabilityId', '');
      setFieldValue('responsibleSchoolAccountabilityId', '');
      setFieldValue('outcomeAEntryScore', '');
      setFieldValue('outcomeAEntryScoreDate', '');
      setFieldValue('outcomeAExitScore', '');
      setFieldValue('outcomeAExitScoreDate', '');
      setFieldValue('outcomeAProgress', '');
      setFieldValue('outcomeBEntryScore', '');
      setFieldValue('outcomeBEntryScoreDate', '');
      setFieldValue('outcomeBExitScore', '');
      setFieldValue('outcomeBExitScoreDate', '');
      setFieldValue('outcomeBProgress', '');
      setFieldValue('outcomeCEntryScore', '');
      setFieldValue('outcomeCEntryScoreDate', '');
      setFieldValue('outcomeCExitScore', '');
      setFieldValue('outcomeCExitScoreDate', '');
      setFieldValue('outcomeCProgress', '');
    }
  };

  const handlePrefillDistrictAndSchoolIfSPED = (
    schoolType: string,
    schoolId: string
  ): void => {
    if (schoolType === NPSESchoolType.SPED) {
      const district = getDistrict(
        getCurrentUserAccess()?.currentDistrict?.districtId,
        formViewModel.districts
      );
      if (district) {
        setFieldValue(
          'responsibleDistrictAccountabilityId',
          district.districtId
        );
        const school = getSchool(district, schoolId);
        if (school) {
          setFieldValue('responsibleSchoolAccountabilityId', school.schoolId);
        }
      }
    }
  };

  const handleSchoolTypeChange = (e: ChangeEvent<HTMLButtonElement>): void => {
    const schoolType = e.target.value;

    clearNonUsedFieldForOtherSchoolType(schoolType);
    const schoolId = prefillSchoolNameAndNumber(schoolType);
    handlePrefillDistrictAndSchoolIfSPED(schoolType, schoolId);
    setFieldValue('schoolTypeInvisible', e.target.value);
  };

  return (
    <Panel panelType={PanelType.INFO} heading="LEA & School Information">
      <div className="field-row field-row-lea">
        <FormTextbox
          displayName="LEA"
          field="district"
          disabled={true}
          validationSchema={NonPublicStudentEntryValidationSchema}
        />
        <FormTextbox
          displayName="School Year"
          field="schoolYear"
          disabled={true}
          validationSchema={NonPublicStudentEntryValidationSchema}
        />
        <FormRadioList
          displayName="School Type"
          field="schoolType"
          options={formViewModel.schoolTypeOptions}
          optionOrientation={FormRadioListOrientation.Horizontal}
          validationSchema={NonPublicStudentEntryValidationSchema}
          disabled={isStateNonAdmin()}
          onClick={handleSchoolTypeChange}
        />
        <FormRadioList
          displayName="School Type Invisible"
          field="schoolTypeInvisible"
          options={formViewModel.schoolTypeOptions}
          optionOrientation={FormRadioListOrientation.Horizontal}
          validationSchema={NonPublicStudentEntryValidationSchema}
          classConfig={{ containerClasses: 'school-type-invisible' }}
        />
        <FormTextbox
          displayName="School"
          field="school"
          disabled={true}
          validationSchema={NonPublicStudentEntryValidationSchema}
        />
      </div>
    </Panel>
  );
};

export default NPSE_LEAPanel;
