/* eslint-disable max-len */
import { ReactElement, useState } from 'react';
import { ButtonSize } from '../../../types/ButtonSize';
import { NoStudentMatchPropTypes } from '../../../types/propTypes/NoStudentMatchPropTypes';
import ActionButton from '../../ActionButton/ActionButton';
import StyledCheckbox from '../../StyledCheckbox/StyledCheckbox';
import { isStateHelpdesk } from 'src/utilities/userUtilities';

const NoStudentMatchSelection = ({
  handleAction,
}: NoStudentMatchPropTypes): ReactElement => {
  const [isMatch, setIsMatch] = useState(false);

  const handleNoMatch = async (): Promise<void> => {
    await handleAction();
  };

  const handleToggle = (toggle: boolean): void => {
    setIsMatch(toggle);
  };

  return (
    <div data-testid="no-match-selection">
      <StyledCheckbox
        onChecked={() => handleToggle(true)}
        onUnchecked={() => handleToggle(false)}
        labelText="None of the students are matches"
        cypressDataId="no-match-checkbox"
      />
      {isMatch && !isStateHelpdesk() && (
        <ActionButton
          cypressDataId="no-match-button"
          onClick={handleNoMatch}
          classes="button--secondary"
          size={ButtonSize.SMALL}
          tooltipText="Create New ID"
        >
          <span>Create New ID</span>
        </ActionButton>
      )}
    </div>
  );
};

export default NoStudentMatchSelection;
