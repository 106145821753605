/* eslint-disable max-len */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataEntryMode } from '../../types/DataEntryMode';
import { LEAManagementFormViewModel } from '../../types/LEAManagementFormViewModel';
import { LEAManagementListItemModel } from '../../types/LEAManagementListItemModel';
import { LEAManagementModel } from '../../types/LEAManagementModel';
import { isEmptyObject } from '../../utilities/helperUtilities';
import type { RootState } from '../store';
import {
  fetchLEAManagementById,
  fetchLEAManagementFormViewModel,
  fetchLEAManagementListItems,
} from '../thunks/leaManagementThunks';

interface LEAManagementState {
  loadingListItems: boolean;
  listItems: LEAManagementListItemModel[];

  loadingLeaManagement: boolean;
  leaManagement: LEAManagementModel;

  loadingFormViewModel: boolean;
  formViewModel: LEAManagementFormViewModel;

  dataEntryMode: DataEntryMode;
}

export const defaultListState: LEAManagementListItemModel[] = [];

export const defaultLEAManagementState: LEAManagementModel = {
  id: '',
  leaName: '',
  organizationType: 'LEA',
  leaType: '',
  leaIdentifier: '',
  ncesIdentifier: '',
  leaOperationalStatus: 'Open',
  operationalStatusEffectiveDate: '',
  charterSchoolIndicator: '',
  reportedFederallyIndicator: '',
  prekEligibility: '',
  prekEligibilityAgesForNonIdeaStudents: '',
  website: '',
  telephoneNumber: '',
  grades: [],
  primaryPositionTitle: '',
  primaryFirstName: '',
  primaryLastName: '',
  primaryEmailAddress: '',
  PrimaryTelephoneNumber: '',
  secondaryPositionTitle: '',
  secondaryFirstName: '',
  secondaryLastName: '',
  secondaryEmailAddress: '',
  secondaryTelephoneNumber: '',
  mailingAddressStreetNumberAndName: '',
  mailingAptSuiteBuildingFloorNumber: '',
  mailingCity: '',
  mailingState: '',
  mailingZipCode: '',
  mailingCounty: '',
  mailingCountyAnsiCode: '',
  physicalAddressStreetNumberAndName: '',
  physicalAptSuiteBuildingFloorNumber: '',
  physicalCity: '',
  physicalState: '',
  physicalZipCode: '',
  physicalCounty: '',
  physicalCountyAnsiCode: '',
};

export const defaultFormState: LEAManagementFormViewModel = {
  leaTypeOptions: [],
  leaOperationalStatusOptions: [],
  charterSchoolIndicatorOptions: [],
  reportedFederallyIndicatorOptions: [],
  prekEligibilityOptions: [],
  prekEligibilityAgesForNonIdeaStudentsOptions: [],
  gradesOptions: [],
  stateOptions: [],
  countyOptions: [],
  countyAnsiCodeOptions: [],
};

export const dataEntryMode = DataEntryMode.NEW;

export const initialState: LEAManagementState = {
  loadingListItems: false,
  listItems: defaultListState,
  loadingLeaManagement: false,
  leaManagement: defaultLEAManagementState,
  loadingFormViewModel: false,
  formViewModel: defaultFormState,
  dataEntryMode: dataEntryMode,
};

export const leaManagementSlice = createSlice({
  name: 'leaManagement',
  initialState,
  reducers: {
    clearLEAManagement: (state) => {
      state.leaManagement = defaultLEAManagementState;
    },
    saveLEAManagement: (state, action: PayloadAction<LEAManagementModel>) => {
      state.leaManagement = action.payload;
    },
    setLEAManagementDataEntryMode: (
      state,
      action: PayloadAction<DataEntryMode>
    ) => {
      state.dataEntryMode = action.payload;
    },
  },
  extraReducers: (builder) => {
    // fetchLeaManagementList
    builder.addCase(fetchLEAManagementListItems.pending, (state) => {
      state.listItems = defaultListState;
      state.loadingListItems = true;
    });
    builder.addCase(fetchLEAManagementListItems.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.listItems = action.payload;
      }
      state.loadingListItems = false;
    });
    builder.addCase(fetchLEAManagementListItems.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loadingListItems = false;
      }
    });

    // fetchLeaManagementById
    builder.addCase(fetchLEAManagementById.pending, (state) => {
      state.leaManagement = defaultLEAManagementState;
      state.loadingLeaManagement = true;
    });
    builder.addCase(fetchLEAManagementById.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.leaManagement = action.payload;
      }
      state.loadingLeaManagement = false;
    });
    builder.addCase(fetchLEAManagementById.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loadingLeaManagement = false;
      }
    });

    // fetchLeaManagementFormViewModel
    builder.addCase(fetchLEAManagementFormViewModel.pending, (state) => {
      state.formViewModel = defaultFormState;
      state.loadingFormViewModel = true;
    });
    builder.addCase(
      fetchLEAManagementFormViewModel.fulfilled,
      (state, action) => {
        if (!isEmptyObject(action.payload)) {
          state.formViewModel = action.payload;
        }
        state.loadingFormViewModel = false;
      }
    );
    builder.addCase(
      fetchLEAManagementFormViewModel.rejected,
      (state, action) => {
        if (!action.meta.aborted) {
          state.loadingFormViewModel = false;
        }
      }
    );
  },
});

//export reducers
export const {
  clearLEAManagement,
  saveLEAManagement,
  setLEAManagementDataEntryMode,
} = leaManagementSlice.actions;

// listItems
export const selectLEAManagementLoadingListItems = (
  state: RootState
): boolean => state.leaManagement.loadingListItems;
export const selectLEAManagementListItems = (
  state: RootState
): LEAManagementListItemModel[] => state.leaManagement.listItems;

// leaManagement
export const selectLEAManagementLoadingLEAManagement = (
  state: RootState
): boolean => state.leaManagement.loadingLeaManagement;
export const selectLEAManagementLEAManagement = (
  state: RootState
): LEAManagementModel => state.leaManagement.leaManagement;

// formViewModel
export const selectLEAManagementLoadingFormViewModel = (
  state: RootState
): boolean => state.leaManagement.loadingFormViewModel;
export const selectLEAManagementFormViewModel = (
  state: RootState
): LEAManagementFormViewModel => state.leaManagement.formViewModel;

// dataEntryMode
export const selectLEAManagementDataEntryMode = (
  state: RootState
): DataEntryMode => state.leaManagement.dataEntryMode;

export default leaManagementSlice.reducer;
