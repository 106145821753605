import { ReactElement } from 'react';
// eslint-disable-next-line max-len
import { isStateHelpdesk } from 'src/utilities/userUtilities';
import { SelectFilesPropTypes } from '../../../types/propTypes/SelectFilesPropTypes';

const SelectFiles = ({ onChange }: SelectFilesPropTypes): ReactElement => {
  return (
    <form method="post" encType="multipart/form-data">
      <label htmlFor="files--create--files">File Upload</label>
      <input
        type="file"
        id="files--create--files"
        name="files"
        data-testid="file-input"
        disabled={isStateHelpdesk()}
        onChange={(e) => {
          onChange(e.target.files);
        }}
      />
    </form>
  );
};

export default SelectFiles;
