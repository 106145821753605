/* eslint-disable max-len */
import { Box, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import {
  DateRange,
  DateRangeValidationError,
} from '@mui/x-date-pickers-pro/models';
import dayjs, { Dayjs } from 'dayjs';
import React, { ReactElement, useEffect, useState } from 'react';
import ActionButton from '../../components/ActionButton/ActionButton';
import EmptyGridContent from '../../components/EmptyGridContent/EmptyGridContent';
import NavigationButton from '../../components/NavigationButton/NavigationButton';
import NavigationPage from '../../components/NavigationPage/NavigationPage';
import Pill from '../../components/Pill/Pill';
import { useConfirmationModalContext } from '../../hooks/useConfirmationModalContext';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectMSISRequests,
  selectStudentLoading,
} from '../../redux/slices/studentSlice';
import { fetchMSISRequests } from '../../redux/thunks/studentThunks';
import {
  abortPromiseOnUnmount,
  isValidCode,
} from '../../services/base.service';
import { appRoutePaths } from '../../services/route.service';
import { canUserAccessRoute } from '../../services/security.service';
import { deleteMsisIdRequest } from '../../services/student.service';
import { ApiResponseModel } from '../../types/ApiResponseModel';
import { ExportFieldOption } from '../../types/ExportFieldOption';
import { FILE_EXPORT_TYPE } from '../../types/FileExportType';
import { MSISRequestModel } from '../../types/MSISRequestModel';
import { PillType } from '../../types/PillType';
import { IconType } from '../../types/propTypes/EmptyGridPropTypes';
import { formatToDateString } from '../../utilities/dateUtilities';
import { spawnFileDownload } from '../../utilities/helperUtilities';
import { last4Digits } from '../../utilities/textUtilities';
import { isStateHelpdesk } from '../../utilities/userUtilities';
import './MSISRequest.css';

const MSISRequest = (): ReactElement => {
  const confirmContext = useConfirmationModalContext();
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectStudentLoading);
  const students: MSISRequestModel[] = useAppSelector(selectMSISRequests);

  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const [error, setError] = useState<DateRangeValidationError>([null, null]);

  const defaultEndDate = dayjs();
  const defaultStartDate = dayjs().subtract(30, 'day');
  const schoolYearStartDate = dayjs().subtract(365, 'day');
  const schoolYearEndDate = dayjs();

  const [dateRangeValue, setDateRangeValue] = useState<DateRange<Dayjs>>([
    defaultStartDate,
    defaultEndDate,
  ]);
  useEffect(() => {
    let promise: unknown;
    if (isInitialLoad) {
      promise = dispatch(
        fetchMSISRequests({
          startDate: defaultStartDate.format('YYYY-MM-DD'),
          endDate: defaultEndDate.format('YYYY-MM-DD'),
        })
      );
    }
    return () => {
      abortPromiseOnUnmount(promise);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleExport = (e: React.MouseEvent<HTMLElement>): void => {
    /* istanbul ignore next */
    e && e.preventDefault();

    const orderedFieldList: ExportFieldOption[] = [
      { fieldName: 'requestingDistrictId', columnName: 'District Identifier' },
      { fieldName: 'requestingDistrictName', columnName: 'District Name' },
      { fieldName: 'schoolId', columnName: 'School Identifier' },
      { fieldName: 'schoolName', columnName: 'School Name' },
      {
        fieldName: 'localStudentIdentifier',
        columnName: 'Local Student Identifier',
      },
      { fieldName: 'firstName', columnName: 'First Name' },
      { fieldName: 'middleName', columnName: 'Middle Name' },
      { fieldName: 'lastName', columnName: 'Last Name' },
      {
        fieldName: 'ssn',
        columnName: 'SSN',
        manipulationFunction: last4Digits,
      },
      { fieldName: 'dateOfBirth', columnName: 'Birthdate' },
      { fieldName: 'msisId', columnName: 'MSIS ID' },
    ];

    const downloadFileName = `MSIS_ID_REQUEST_${new Date().toISOString()}`;
    spawnFileDownload(
      students,
      orderedFieldList,
      downloadFileName,
      FILE_EXPORT_TYPE.CSV
    );
  };

  const handleChange = (newValue: DateRange<Dayjs>): void => {
    setDateRangeValue(newValue);
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    const [_, end] = newValue;
    const today = dayjs();

    if (end && end.isAfter(today)) {
      setError([null, 'disableFuture']);
    } else if (
      end &&
      !end.isAfter(today) &&
      error &&
      error[1] === 'disableFuture'
    ) {
      setError([null, null]);
    }
  };

  const errorMessage = React.useMemo(() => {
    let startErrorMessage = '';
    switch (error[0]) {
      case 'invalidDate': {
        startErrorMessage = 'Start date is invalid.';
        break;
      }
      case 'invalidRange': {
        startErrorMessage = 'Start date cannot be greater than end date.';
        break;
      }
      case 'minDate': {
        startErrorMessage = `Start & end date should be with in ${schoolYearStartDate.format(
          'MM/DD/YYYY'
        )} - ${schoolYearEndDate.format('MM/DD/YYYY')}.`;
        break;
      }
      case 'maxDate': {
        startErrorMessage = `Start & end date should be with in ${schoolYearStartDate.format(
          'MM/DD/YYYY'
        )} - ${schoolYearEndDate.format('MM/DD/YYYY')}.`;
        break;
      }
    }

    let endErrorMessage = '';
    switch (error[1]) {
      case 'invalidDate': {
        endErrorMessage = 'End date is invalid';
        break;
      }
      case 'invalidRange': {
        endErrorMessage = 'End date cannot be less than start date.';
        break;
      }
      case 'minDate': {
        endErrorMessage = `Start & end date should be with in ${schoolYearStartDate.format(
          'MM/DD/YYYY'
        )} - ${schoolYearEndDate.format('MM/DD/YYYY')}.`;
        break;
      }
      case 'maxDate': {
        endErrorMessage = `Start & end date should be with in ${schoolYearStartDate.format(
          'MM/DD/YYYY'
        )} - ${schoolYearEndDate.format('MM/DD/YYYY')}.`;
        break;
      }
      case 'disableFuture': {
        endErrorMessage = 'End Date cannot exceed the current date';
        break;
      }
    }
    return startErrorMessage || endErrorMessage;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleSubmit = (): void => {
    setIsInitialLoad(false);
    dispatch(
      fetchMSISRequests({
        startDate: dateRangeValue[0]?.format('YYYY-MM-DD') || '',
        endDate: dateRangeValue[1]?.format('YYYY-MM-DD') || '',
      })
    );
  };

  const filterDropdown = (
    <>
      <Typography variant="h6" sx={{ mt: 2 }} gutterBottom>
        Select start and end dates (within the previous 365 days), then select
        Apply Filter.
      </Typography>
      <div className="container">
        <div>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{ start: '', end: '' }}
          >
            <Box sx={{ width: 500, padding: 2 }}>
              <DateRangePicker
                value={dateRangeValue}
                defaultValue={[defaultStartDate, defaultEndDate]}
                minDate={schoolYearStartDate}
                maxDate={schoolYearEndDate}
                onChange={(newValue) => handleChange(newValue)}
                onError={([startError, endError]) =>
                  setError([startError, endError])
                }
              />
              <Typography sx={{ color: 'red' }}>{errorMessage}</Typography>
            </Box>
          </LocalizationProvider>
        </div>
        <div>
          <ActionButton
            onClick={handleSubmit}
            classes="no-wrap-text apply-filter"
            tooltipText="apply filter"
            disabled={errorMessage === '' ? false : true}
          >
            <> Apply Filter</>
          </ActionButton>
        </div>
      </div>
    </>
  );

  const buttons = (
    <>
      {students.length > 0 && (
        <ActionButton
          onClick={handleExport}
          classes="no-wrap-text export--button"
          tooltipText="Export Ids to CSV"
        >
          <> Export IDs</>
        </ActionButton>
      )}
      {!isStateHelpdesk() && (
        <NavigationButton
          route={appRoutePaths.MSISRequestNew}
          classes="button no-wrap-text new-button"
          tooltipText="Add New MSIS ID Request"
          visibleOverride={canUserAccessRoute(appRoutePaths.MSISRequestNew)}
        >
          <>Add New</>
        </NavigationButton>
      )}
    </>
  );

  const handleDelete = async (id: string, fullName: string): Promise<void> => {
    confirmContext.setOptions({
      title: `Cancel MSIS ID Request?`,
      content: (
        <div className="confirm--message">
          Are you sure you want to cancel the MSIS ID Request for &quot;
          {fullName}&quot;?
        </div>
      ),
      confirmText: 'Yes',
      cancelText: 'No',
      onOk: async (): Promise<ApiResponseModel<unknown>> => {
        const response = await deleteMsisIdRequest(id);
        if (isValidCode(response.status)) {
          dispatch(
            fetchMSISRequests({
              startDate: dateRangeValue[0]?.format('YYYY-MM-DD') || '',
              endDate: dateRangeValue[1]?.format('YYYY-MM-DD') || '',
            })
          );
        }
        return response;
      },
    });

    await confirmContext.showConfirmation();
  };

  return (
    <NavigationPage
      heading="MSIS ID Requests"
      pageClass="msis-request--list"
      isLoading={isLoading}
      loadingDataId="msis-request-loader"
      loadingText="Getting MSIS Requests"
    >
      <div className="filter-container" data-testid="filter-container">
        <div className="filter-dropdown">{filterDropdown}</div>
        <div className="heading-buttons">{buttons}</div>
      </div>
      <div>
        <table cellSpacing={0} className="page-view-table">
          <thead>
            <tr>
              <th data-cy="msis-request-name">Name</th>
              <th data-cy="msis-request-dob">Date of Birth</th>
              <th data-cy="msis-request-days-old">Request Age (Days)</th>
              <th data-cy="msis-request-msis-id">MSIS ID</th>
              <th data-cy="msis-request-status">Status</th>
              <th data-cy="msis-request-action">Actions</th>
            </tr>
          </thead>
          <tbody>
            {students.length > 0 &&
              students.map((student) => (
                <tr
                  key={`${student.id}`}
                  data-testid={`msis-request-${student.id}`}
                  data-status={student.status}
                  data-cy="msis-request-data-row"
                >
                  <td
                    className="msis-request--list--full-name"
                    data-cy="msis-request-data-full-name"
                  >
                    <NavigationButton
                      route={appRoutePaths.MSISRequestDetail(student.id)}
                      visibleOverride={canUserAccessRoute(
                        appRoutePaths.MSISRequestDetail()
                      )}
                      tooltipText="Click to view detail"
                    >
                      <>{student.fullName}</>
                    </NavigationButton>
                  </td>
                  <td
                    className="msis-request--list--date-of-birth"
                    data-cy="msis-request-data-date-of-birth"
                  >
                    {formatToDateString(student.dateOfBirth)}
                  </td>
                  <td data-cy="msis-request-data-days-old">
                    {student.requestAgeDays}
                  </td>
                  <td
                    className="msis-request--list--msis-id"
                    data-cy="msis-request-data-msis-id"
                  >
                    {student.msisId}
                  </td>
                  <td
                    className="msis-request--list--msis-status"
                    data-cy="msis-request-data-status"
                  >
                    <Pill
                      status={student.status}
                      value={student.status}
                      pillType={PillType.MSIS_REQUEST_STATUS}
                    />
                  </td>
                  <td className="msis-request--list--action-container">
                    <div className="action--column">
                      {student.status === 'Review Needed' &&
                        !isStateHelpdesk() && (
                          <ActionButton
                            onClick={async () => {
                              await handleDelete(student.id, student.fullName);
                            }}
                            dataTestId={`delete-request-${student.id}`}
                            classes="no-button-padding"
                            tooltipText="Cancel MSIS ID Request"
                          >
                            <img src={'/images/icon-unassign.svg'} />
                          </ActionButton>
                        )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {!isLoading && students.length === 0 && (
          <EmptyGridContent iconType={IconType.FILE}>
            <p>No records found for the selected dates</p>
          </EmptyGridContent>
        )}
      </div>
    </NavigationPage>
  );
};

export default MSISRequest;
