/* eslint-disable max-len */
import { ApiResponseModel } from '../types/ApiResponseModel';
import { DataQualityDashboardModel } from '../types/DataQualityDashboardModel';
import {
  DataQualityDashboardRequest,
  DataQualityErrorsRequest,
} from '../types/DataQualityDashboardRequest';
import { DataQualityErrorList } from '../types/DataQualityErrorList';
import { DataRequestConfig } from '../types/DataRequestConfig';
import { getApiBaseUrl } from './app.service';
import { executeApiRequest, getAuthToken } from './base.service';
import { getHttpClient, HttpClient } from './httpClient';

export const dataQualityApiPaths = {
  dataQuality: (params?: DataQualityDashboardRequest): string =>
    `/dataquality?optionalCertificationDataCollection=${params?.filter}`,
  getDataQualityErrorsByDomain: (params: DataQualityErrorsRequest): string =>
    `/dataquality/error-list?domain=${params.domain}&optionalCertificationDataCollection=${params.filter}&errorLevel=${params.errorLevel}`,
};

export const DataQualityServiceClient = (
  config?: DataRequestConfig
): HttpClient =>
  getHttpClient(getApiBaseUrl(), getAuthToken() || '', config?.signal);

export const getDataQualityDashboard = async (
  params?: DataQualityDashboardRequest,
  config?: DataRequestConfig
): Promise<ApiResponseModel<DataQualityDashboardModel>> => {
  return executeApiRequest<DataQualityDashboardModel>(async () => {
    return await DataQualityServiceClient(config).get(
      dataQualityApiPaths.dataQuality(params)
    );
  });
};

export const getDataQualityErrorsByCategory = async (
  params: DataQualityErrorsRequest,
  config?: DataRequestConfig
): Promise<ApiResponseModel<DataQualityErrorList>> => {
  return executeApiRequest<DataQualityErrorList>(async () => {
    return await DataQualityServiceClient(config).get(
      dataQualityApiPaths.getDataQualityErrorsByDomain(params)
    );
  });
};
