import { ReactElement } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import {
  selectFailureIds,
} from '../../../redux/slices/dashboardSlice';
import { appRoutePaths } from '../../../services/route.service';
import { canUserAccessRoute } from '../../../services/security.service';
import FailureCard from '../FailureCard/FailureCard';
import SuccessCard from '../SuccessCard/SuccessCard';

const FileStatusCard = (): ReactElement => {
  const failureFileIds = useAppSelector(selectFailureIds);

  const failureCount = failureFileIds.length;

  const hasFailure = failureCount > 0;
  const errorMessage =
    failureCount === 1 ? 'Failed file to review' : 'Failed files to review';
  const errorButtonText = failureCount === 1 ? 'View File' : 'View Files';
  const buttonLink =
    failureCount === 1
      ? `${appRoutePaths.FileDetail(failureFileIds[0])}`
      : `${appRoutePaths.SubmissionStatus}`;
  const buttonVisible =
    failureCount === 1
      ? canUserAccessRoute(appRoutePaths.FileDetail())
      : canUserAccessRoute(appRoutePaths.SubmissionStatus);

  if (!hasFailure) {
    return <SuccessCard message="Files Uploaded" />;
  } else {
    return (
      <FailureCard
        message={errorMessage}
        buttonText={errorButtonText}
        buttonLink={buttonLink}
        buttonVisible={buttonVisible}
        tooltipText="Click to review files"
      />
    );
  }
};

export default FileStatusCard;
